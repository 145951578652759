<template>
    <LiefengContent>
        <template v-slot:title>机构积分管理</template>
        <template v-slot:toolsbarLeft>
            <Input :maxlength="20" v-model.trim="searchModel.userName" placeholder="姓名" style="width: 150px; margin-right: 10px" />
            <Input :maxlength="20" v-model.trim="searchModel.mobile" placeholder="手机号" style="width: 150px; margin-right: 10px" />
            <!-- <Select v-model="searchModel.model" style="width: 120px; margin-right: 5px" placeholder="全部模式" clearable>
                <Option :value="item.dictKey" v-for="(item, index) in selectList" :key="index">{{ item.dictValue }}</Option>
            </Select> -->
            <Button
                type="primary"
                @click="
                    () => {
                        getList()
                    }
                "
            >
                查询
            </Button>
            <Button
                type="info"
                style="margin-left: 10px"
                @click="
                    () => {
                        searchModel = {
                            userName: '',
                            mobile: '',
                        }
                        getList()
                    }
                "
            >
                重置
            </Button>
        </template>
        <template v-slot:toolsbarRight v-if="$route.query.showBtn == 1">
            <Button type="info" style="margin-right: 20px" @click="changeLayer('/rulepage', '规则说明')">规则说明</Button>
            <Button type="info" style="margin-right: 20px" @click="changeLayer('/pointdefinition', '业务点定义')">业务点定义</Button>
            <Button type="info" style="margin-right: 20px" @click="changeLayer(`/mallintegralrule?orgCode=${orgCode}`, '积分规则设置')">积分规则设置</Button>
        </template>
        <template v-slot:contentArea>
            <div class="leftMenu">
                <LiefengTable
                    :talbeColumns="tableColumns"
                    :tableData="menuList"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    :hidePage="false"
                ></LiefengTable>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/test15minmanage')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: {
        LiefengContent,
        LiefengTable,
    },
    data() {
        return {
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            menuList: [],
            tableColumns: [
                {
                    title: "姓名",
                    width: 250,
                    key: "userName",
                    align: "center",
                },
                {
                    title: "手机号",
                    minWidth: 200,
                    key: "mobile",
                    align: "center",
                },
                {
                    title: "剩余积分",
                    minWidth: 200,
                    key: "pointsCount",
                    align: "center",
                },
                {
                    title: "累计积分",
                    minWidth: 200,
                    key: "pointsTotal",
                    align: "center",
                    render: (h, params) => {
                        return h("div", params.row.pointsTotal && params.row.pointsTotal != "" ? params.row.pointsTotal : 0) 
                    },
                },
                {
                    title: "排行",
                    key: "sort",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", params.row.sort && params.row.sort != "" ? params.row.sort : "1") 
                    },
                },
                {
                    title: "积分开始日期",
                    key: "gmtCreate",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", params.row.gmtCreate && params.row.gmtCreate != "" ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "") 
                    },
                },
                {
                    title: "最近访问时间",
                    key: "lastViewTime",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", params.row.lastViewTime && params.row.lastViewTime != "" ? this.$core.formatDate(new Date(params.row.lastViewTime), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    width: 200,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.$core.openLayerFrame({
                                                    type: 2,
                                                    title: "积分详情",
                                                    content: `/page#/orgintegralruleuser?userId=${params.row.userId}&userName=${params.row.userName}&mobile=${params.row.mobile}`,
                                                    area: ["100%", "100%"],
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "查看" //1001
                                ),
                            ]
                        )
                    },
                },
            ],
            searchModel: {
                userName: "",
                mobile: "",
            },
            orgCode:''
        }
    },
    methods: {
        changeLayer(router, name) {
            this.$core.openLayerFrame({
                type: 2,
                title: name,
                content: `/page#${router}`,
                area: ["100%", "100%"],
            })
        },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.page = obj.page
            this.getList()
        },
        getList() {
            this.loading = true
            this.$get("/gateway/sy-points/api/sypointes/user/listPointsUserByPage", {
                userName: this.searchModel.userName,
                mobile: this.searchModel.mobile,
                page: this.page,
                pageSize: this.pageSize,
                orgCode: window.sessionStorage.getItem("orgCode"),
                poolCode: "P710019",
                startLevelScore:'1'
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.menuList = res.dataList
                    }
                    this.$Message.destroy()
                    this.loading = false
                    this.total = res.maxCount
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
    },
    async created() {
        this.orgCode = window.sessionStorage.getItem("orgCode") || ''
        this.getList()
    },
}
</script>

<style lang='less'>
/deep/.ivu-form-item-content {
    display: flex !important;
}
.ivu-form-item-label {
    display: flex !important;
}
</style>
