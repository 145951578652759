var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LiefengContent',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("机构积分管理")]},proxy:true},{key:"toolsbarLeft",fn:function(){return [_c('Input',{staticStyle:{"width":"150px","margin-right":"10px"},attrs:{"maxlength":20,"placeholder":"姓名"},model:{value:(_vm.searchModel.userName),callback:function ($$v) {_vm.$set(_vm.searchModel, "userName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"searchModel.userName"}}),_c('Input',{staticStyle:{"width":"150px","margin-right":"10px"},attrs:{"maxlength":20,"placeholder":"手机号"},model:{value:(_vm.searchModel.mobile),callback:function ($$v) {_vm.$set(_vm.searchModel, "mobile", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"searchModel.mobile"}}),_c('Button',{attrs:{"type":"primary"},on:{"click":function () {
                    _vm.getList()
                }}},[_vm._v(" 查询 ")]),_c('Button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"info"},on:{"click":function () {
                    _vm.searchModel = {
                        userName: '',
                        mobile: '',
                    }
                    _vm.getList()
                }}},[_vm._v(" 重置 ")])]},proxy:true},(_vm.$route.query.showBtn == 1)?{key:"toolsbarRight",fn:function(){return [_c('Button',{staticStyle:{"margin-right":"20px"},attrs:{"type":"info"},on:{"click":function($event){return _vm.changeLayer('/rulepage', '规则说明')}}},[_vm._v("规则说明")]),_c('Button',{staticStyle:{"margin-right":"20px"},attrs:{"type":"info"},on:{"click":function($event){return _vm.changeLayer('/pointdefinition', '业务点定义')}}},[_vm._v("业务点定义")]),_c('Button',{staticStyle:{"margin-right":"20px"},attrs:{"type":"info"},on:{"click":function($event){return _vm.changeLayer(("/mallintegralrule?orgCode=" + _vm.orgCode), '积分规则设置')}}},[_vm._v("积分规则设置")])]},proxy:true}:null,{key:"contentArea",fn:function(){return [_c('div',{staticClass:"leftMenu"},[_c('LiefengTable',{attrs:{"talbeColumns":_vm.tableColumns,"tableData":_vm.menuList,"loading":_vm.loading,"fixTable":true,"curPage":_vm.page,"total":_vm.total,"pagesizeOpts":[20, 30, 50, 100],"page-size":_vm.pageSize,"hidePage":false},on:{"hadlePageSize":_vm.hadlePageSize}})],1)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }